import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7feb02dc = () => interopDefault(import('../pages/acceptance.vue' /* webpackChunkName: "pages/acceptance" */))
const _38167cb0 = () => interopDefault(import('../pages/confirmation.vue' /* webpackChunkName: "pages/confirmation" */))
const _205eeac1 = () => interopDefault(import('../pages/cover-details.vue' /* webpackChunkName: "pages/cover-details" */))
const _67e9f71b = () => interopDefault(import('../pages/licence-details.vue' /* webpackChunkName: "pages/licence-details" */))
const _40e4c776 = () => interopDefault(import('../pages/manual-lookup.vue' /* webpackChunkName: "pages/manual-lookup" */))
const _a46bc14a = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _78a69032 = () => interopDefault(import('../pages/payment-issue.vue' /* webpackChunkName: "pages/payment-issue" */))
const _28384491 = () => interopDefault(import('../pages/payment-success.vue' /* webpackChunkName: "pages/payment-success" */))
const _628502b0 = () => interopDefault(import('../pages/personal-details.vue' /* webpackChunkName: "pages/personal-details" */))
const _4c1a3d06 = () => interopDefault(import('../pages/quote-recall.vue' /* webpackChunkName: "pages/quote-recall" */))
const _1a36519c = () => interopDefault(import('../pages/quotes.vue' /* webpackChunkName: "pages/quotes" */))
const _48ab9426 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _c8b4ea4c = () => interopDefault(import('../pages/additional-driver/licence-details.vue' /* webpackChunkName: "pages/additional-driver/licence-details" */))
const _b388325e = () => interopDefault(import('../pages/additional-driver/personal-details.vue' /* webpackChunkName: "pages/additional-driver/personal-details" */))
const _d9d9c932 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/acceptance",
    component: _7feb02dc,
    name: "acceptance"
  }, {
    path: "/confirmation",
    component: _38167cb0,
    name: "confirmation"
  }, {
    path: "/cover-details",
    component: _205eeac1,
    name: "cover-details"
  }, {
    path: "/licence-details",
    component: _67e9f71b,
    name: "licence-details"
  }, {
    path: "/manual-lookup",
    component: _40e4c776,
    name: "manual-lookup"
  }, {
    path: "/payment",
    component: _a46bc14a,
    name: "payment"
  }, {
    path: "/payment-issue",
    component: _78a69032,
    name: "payment-issue"
  }, {
    path: "/payment-success",
    component: _28384491,
    name: "payment-success"
  }, {
    path: "/personal-details",
    component: _628502b0,
    name: "personal-details"
  }, {
    path: "/quote-recall",
    component: _4c1a3d06,
    name: "quote-recall"
  }, {
    path: "/quotes",
    component: _1a36519c,
    name: "quotes"
  }, {
    path: "/unsubscribe",
    component: _48ab9426,
    name: "unsubscribe"
  }, {
    path: "/additional-driver/licence-details",
    component: _c8b4ea4c,
    name: "additional-driver-licence-details"
  }, {
    path: "/additional-driver/personal-details",
    component: _b388325e,
    name: "additional-driver-personal-details"
  }, {
    path: "/",
    component: _d9d9c932,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
