export { default as ConfirmationCosts } from '../../components/confirmation/Costs.vue'
export { default as ConfirmationDocuments } from '../../components/confirmation/Documents.vue'
export { default as ConfirmationDriverDetails } from '../../components/confirmation/DriverDetails.vue'
export { default as ConfirmationMessage } from '../../components/confirmation/Message.vue'
export { default as ConfirmationOptionalExtraCard } from '../../components/confirmation/OptionalExtraCard.vue'
export { default as ConfirmationVehicleAndInsuranceDetails } from '../../components/confirmation/VehicleAndInsuranceDetails.vue'
export { default as AdditionalDriverToggle } from '../../components/additionalDriver/Toggle.vue'
export { default as FormContainer } from '../../components/form/Container.vue'
export { default as FormLabel } from '../../components/form/Label.vue'
export { default as FormLabelBox } from '../../components/form/LabelBox.vue'
export { default as FormProgressBar } from '../../components/form/ProgressBar.vue'
export { default as FormTitle } from '../../components/form/Title.vue'
export { default as BaseBottomNavigation } from '../../components/base/BottomNavigation.vue'
export { default as BaseButton } from '../../components/base/Button.vue'
export { default as BaseButtonToggle } from '../../components/base/ButtonToggle.vue'
export { default as BaseCard } from '../../components/base/Card.vue'
export { default as BaseDebugger } from '../../components/base/Debugger.vue'
export { default as BaseError } from '../../components/base/Error.vue'
export { default as BaseFooter } from '../../components/base/Footer.vue'
export { default as BaseLoader } from '../../components/base/Loader.vue'
export { default as BaseModal } from '../../components/base/Modal.vue'
export { default as BaseNavigation } from '../../components/base/Navigation.vue'
export { default as BasePaymentSheet } from '../../components/base/PaymentSheet.vue'
export { default as BaseSessionModal } from '../../components/base/SessionModal.vue'
export { default as BaseTermsAndPrivacy } from '../../components/base/TermsAndPrivacy.vue'
export { default as BaseTooltips } from '../../components/base/Tooltips.vue'
export { default as LicenceCountry } from '../../components/licence/Country.vue'
export { default as LicenceDetailsInfoCard } from '../../components/licence/DetailsInfoCard.vue'
export { default as LicenceDetailsPopout } from '../../components/licence/DetailsPopout.vue'
export { default as LicenceDurationHeld } from '../../components/licence/DurationHeld.vue'
export { default as LicenceIssuingCountry } from '../../components/licence/IssuingCountry.vue'
export { default as LicenceType } from '../../components/licence/Type.vue'
export { default as CoverDurationLength } from '../../components/cover/DurationLength.vue'
export { default as CoverDurationType } from '../../components/cover/DurationType.vue'
export { default as CoverLengthButtons } from '../../components/cover/LengthButtons.vue'
export { default as CoverLengthSelection } from '../../components/cover/LengthSelection.vue'
export { default as CoverReason } from '../../components/cover/Reason.vue'
export { default as CoverStartDate } from '../../components/cover/StartDate.vue'
export { default as CoverStartTime } from '../../components/cover/StartTime.vue'
export { default as MaintenanceMessage } from '../../components/maintenance/Message.vue'
export { default as MaintenanceWarningBar } from '../../components/maintenance/WarningBar.vue'
export { default as MarketingCaptureForm } from '../../components/marketing/CaptureForm.vue'
export { default as PersonalAddressFinder } from '../../components/personal/AddressFinder.vue'
export { default as PersonalAddressInfoCard } from '../../components/personal/AddressInfoCard.vue'
export { default as PersonalAddresses } from '../../components/personal/Addresses.vue'
export { default as PersonalDateOfBirth } from '../../components/personal/DateOfBirth.vue'
export { default as PersonalEmail } from '../../components/personal/Email.vue'
export { default as PersonalMobilePhone } from '../../components/personal/MobilePhone.vue'
export { default as PersonalName } from '../../components/personal/Name.vue'
export { default as PersonalOccupation } from '../../components/personal/Occupation.vue'
export { default as PersonalPostcode } from '../../components/personal/Postcode.vue'
export { default as PersonalRelationship } from '../../components/personal/Relationship.vue'
export { default as PersonalSameAsMainDriver } from '../../components/personal/SameAsMainDriver.vue'
export { default as PersonalTitle } from '../../components/personal/Title.vue'
export { default as QuoteCard } from '../../components/quote/Card.vue'
export { default as QuoteOptionalExtrasCard } from '../../components/quote/OptionalExtrasCard.vue'
export { default as QuotePromoBox } from '../../components/quote/PromoBox.vue'
export { default as QuoteTweakPanel } from '../../components/quote/TweakPanel.vue'
export { default as RegistrationNumber } from '../../components/registration/Number.vue'
export { default as SpinnerBranded } from '../../components/spinner/Branded.vue'
export { default as SpinnerUnbranded } from '../../components/spinner/Unbranded.vue'
export { default as TrustpilotWidget } from '../../components/trustpilot/Widget.vue'
export { default as TrustpilotWidgetV2 } from '../../components/trustpilot/WidgetV2.vue'
export { default as Under21CarryingPassengers } from '../../components/under21/CarryingPassengers.vue'
export { default as Under21DrivingHours } from '../../components/under21/DrivingHours.vue'
export { default as Under21DrivingLocation } from '../../components/under21/DrivingLocation.vue'
export { default as VehicleAnnualInsurance } from '../../components/vehicle/AnnualInsurance.vue'
export { default as VehicleDescription } from '../../components/vehicle/Description.vue'
export { default as VehicleDoors } from '../../components/vehicle/Doors.vue'
export { default as VehicleEngineSize } from '../../components/vehicle/EngineSize.vue'
export { default as VehicleFuel } from '../../components/vehicle/Fuel.vue'
export { default as VehicleInfoCard } from '../../components/vehicle/InfoCard.vue'
export { default as VehicleKeeper } from '../../components/vehicle/Keeper.vue'
export { default as VehicleLearnerCarOwner } from '../../components/vehicle/LearnerCarOwner.vue'
export { default as VehicleMake } from '../../components/vehicle/Make.vue'
export { default as VehicleModel } from '../../components/vehicle/Model.vue'
export { default as VehicleOwner } from '../../components/vehicle/Owner.vue'
export { default as VehicleTransmission } from '../../components/vehicle/Transmission.vue'
export { default as VehicleType } from '../../components/vehicle/Type.vue'
export { default as VehicleUsage } from '../../components/vehicle/Usage.vue'
export { default as VehicleValuation } from '../../components/vehicle/Valuation.vue'
export { default as VehicleWeight } from '../../components/vehicle/Weight.vue'
export { default as VehicleYear } from '../../components/vehicle/Year.vue'
export { default as WhiteLabeledLogo } from '../../components/whiteLabeled/Logo.vue'
export { default as LicenceNumberNI } from '../../components/licence/number/NI.vue'
export { default as LicenceNumberOther } from '../../components/licence/number/Other.vue'
export { default as LicenceNumberUK } from '../../components/licence/number/UK.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
