import { render, staticRenderFns } from "./CaptureForm.vue?vue&type=template&id=31960251&scoped=true&"
import script from "./CaptureForm.vue?vue&type=script&lang=js&"
export * from "./CaptureForm.vue?vue&type=script&lang=js&"
import style0 from "./CaptureForm.vue?vue&type=style&index=0&id=31960251&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31960251",
  null
  
)

export default component.exports